<template>
  <v-card>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="eps_import_service()">
        <v-card-title>
          {{ __('Importar lead da EPS', 'leads') }}
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <ValidationProvider name="service_number" rules="required|length:16" v-slot="{ errors }">
          <v-card-text>
            <v-text-field
              name="service_number"
              v-model="service_number"
              :label="
                __('Número do serviço', 'leads')
              "
              placeholder="13EPST0002747.01"
              :error-messages="errors"
              :disabled="loading"
            ></v-text-field>
          </v-card-text>
        </ValidationProvider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" text>{{ __('Cancelar', 'leads') }}</v-btn>
          <v-btn type="submit" color="primary" :disabled="invalid" :loading="loading">
            <v-icon left>mdi-download</v-icon>{{ __('Importar', 'leads') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { getLeadAndService } from "@/services/eps";
import i18n from '@/mixins/i18n';

export default {
  name: "eps-import-lead-form",
  mixins: [i18n],
  data: () => ({
    eps_import_dialog: false,
    service_number: "",
    loading: false,
  }),
  methods: {
    async eps_import_service() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        this.loading = true;
        try {
          await getLeadAndService(this.service_number);
          await this.resetForm();
          this.$emit("submitted");
        } catch (error) {
          this.$store.commit("sendMessage", {
            text: "Falha ao realizar a acção",
            color: "red",
          });
        } finally {
          this.loading = false;
        }
      }
    },
    async cancel() {
      await this.resetForm();
      this.$emit("cancel");
    },
    resetForm() {
      return new Promise((resolve) => {
        this.service_number = "";
        this.$refs.form.reset();
        resolve();
      });
    },
  },
};
</script>