import { formatDate } from "./date";

export function getFilterValueString(filter, max = 0) {
  if (filter.column.type === "date") {
    const dates = [];
    if (filter.value_start) {
      dates.push(formatDate(filter.value_start));
    }
    if (filter.value_end) {
      dates.push(formatDate(filter.value_end));
    }

    return dates.join(", ");
  }

  if (!filter.column.valueItemText) {
    return filter.value;
  }

  if (Array.isArray(filter.value)) {
    if (max > 0 && filter.value.length > max) {
      return filter.value.length + " selecionados";
    }

    return filter.value
      .map((item) => item[filter.column.valueItemText])
      .join(", ");
  }

  return filter.value[filter.column.valueItemText];
}

export function getFilterValueStringResumed(filter, max = 2) {
  return getFilterValueString(filter, max);
}
