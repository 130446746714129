<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title v-if="!selectedFilter">
        Filtros Avançados
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-toolbar v-else elevation="0" outlined>
        <v-btn @click="selectedFilter = null" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="title">
          {{ selectedFilter.name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <ListMenu
          @click:item:save_current="handleSaveSelected()"
          @click:item:save_new="handleClickSaveFilter()"
          :items="[
            {
              id: 'save_current',
              icon: 'mdi-content-save',
              title: 'Salvar',
            },
            {
              id: 'save_new',
              icon: 'mdi-content-save-plus',
              title: 'Salvar como novo filtro',
            },
          ]"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
        </ListMenu>

        <v-menu>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="on" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="handleClickRenameFilter(selectedFilter)">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Renomear </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="handleRemoveFilter(selectedFilter)">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Eliminar </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-sheet v-if="!selectedFilter" class="px-4">
        <v-chip-group v-model="selectedFilter" color="primary" column>
          <v-chip
            @click="applyFilters(filter.advanced_filters)"
            v-for="filter in storedFilters"
            small
            :key="filter.id"
            :value="filter"
          >
            <v-icon small left>mdi-lightning-bolt</v-icon>
            {{ filter.name }}
          </v-chip>
          <v-chip @click.prevent="handleClickSaveFilter()" small :value="null">
            <v-icon small left>mdi-plus</v-icon> Salvar
          </v-chip>
        </v-chip-group>
      </v-sheet>

      <v-simple-table>
        <thead>
          <tr>
            <th>Filtro</th>
            <th>Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="filter in filters"
            :key="filter.id"
            @click="handleUpdateFilter(filter)"
          >
            <td>{{ filter.column.label }}</td>
            <td v-text="getFilterValueString(filter)"></td>
            <td>
              <v-btn @click.stop="removeFilter(filter)" icon small>
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <v-btn @click="handleAddFilter()" text color="primary" small>
                <v-icon left>mdi-plus</v-icon>
                Adicionar regra
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" text> Cancelar </v-btn>
        <v-btn @click="apply()" color="primary"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>

    <AddFilterDialog
      ref="add_filter_dialog"
      :template="template"
      @submit="addFilter"
    />

    <AdvancedFilterSaveDialog
      title="Registar filtro"
      ref="save_dialog"
      @submit="handleSubmitSaveDialog"
    />

    <AdvancedFilterSaveDialog
      title="Renomear filtro"
      ref="rename_dialog"
      @submit="handleSubmitRenameFilter"
    />
  </v-dialog>
</template>

<script>
import AddFilterDialog from "./AddFilterDialog.vue";
import { getFilterValueString } from "@/tools/advanced_filters";
import AdvancedFilterSaveDialog from "./AdvancedFilterSaveDialog.vue";
import { store, rename } from "@/services/advanced_filters";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ListMenu from "@/components/menus/ListMenu.vue";

export default {
  components: {
    AddFilterDialog,
    AdvancedFilterSaveDialog,
    ListMenu,
  },

  mixins: [ToastsMixin],

  data() {
    return {
      dialog: false,
      filters: [],
      getFilterValueString,
      selectedFilter: null,
    };
  },

  props: {
    template: Array,
    tableKey: String,
    storedFilters: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    handleRemoveFilter(filter) {
      this.$emit("removeFilter", filter);
      this.selectedFilter = null;
    },

    handleClickSaveFilter() {
      this.$refs.save_dialog.open();
    },

    handleClickRenameFilter(filter) {
      this.$refs.rename_dialog.open({
        ...filter,
      });
    },

    async handleSubmitRenameFilter(payload) {
      const filter = await rename(payload.id, {
        name: payload.name,
      });

      this.toast("Salvo!");
      this.$refs.rename_dialog.close();
      this.$emit("filter_stored", filter);
      this.selectedFilter = { ...filter };
    },

    async handleSubmitSaveDialog(payload) {
      try {
        const newFilter = await store(this.tableKey, {
          name: payload.name,
          advanced_filters: [...this.filters],
        });

        this.$refs.save_dialog.close();

        this.selectedFilter = { ...newFilter };
        this.$emit("filter_stored", newFilter);
        this.toast(`Filtro '${payload.name}' adicionado`);
      } catch (error) {
        const message = error.response?.data?.message || "";
        const errors = error.response?.data?.errors || {};

        this.$refs.save_dialog.setErrors(message, errors);
      }
    },

    async handleSaveSelected() {
      const newFilter = await store(this.tableKey, {
        name: this.selectedFilter.name,
        advanced_filters: [...this.filters],
      });

      this.$refs.save_dialog.close();
      this.$emit("filter_stored", newFilter);
      this.toast("Salvo!");
    },

    addFilter(filter) {
      if (this.filterExists(filter)) {
        this.replaceFilter(filter);
        return;
      }

      this.filters.push(filter);
    },

    replaceFilter(filter) {
      this.filters = this.filters.map((filterItem) => {
        if (filterItem.column.id === filter.column.id) {
          return filter;
        }

        return filterItem;
      });
    },

    filterExists(filter) {
      return this.filters.find((item) => item.column.id === filter.column.id);
    },

    handleUpdateFilter(filter) {
      this.$refs.add_filter_dialog.open(filter);
    },

    removeFilter(filter) {
      this.filters = this.filters.filter(
        (item) => item.column.id !== filter.column.id
      );
    },

    close() {
      this.dialog = false;
    },

    applyFilters(filters) {
      this.filters = filters.map((filter) => ({
        value: filter.value,
        value_start: filter.value_start,
        value_end: filter.value_end,
        column: this.template.find(
          (templateItem) => templateItem.id === filter.column.id
        ),
      }));
    },

    open(initial = []) {
      this.applyFilters(initial);
      this.dialog = true;
      this.selectedFilter = null;
    },

    async openForEdit(initial = [], filter) {
      await this.open(initial);

      await this.$nextTick();

      this.handleUpdateFilter({
        ...this.filters.find(
          (filterItem) => filterItem.column.id === filter.column.id
        ),
      });
    },

    apply() {
      this.$emit("apply", this.filters);
    },

    handleAddFilter() {
      this.$refs.add_filter_dialog.open();
    },
  },
};
</script>
