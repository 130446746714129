function getParams(options, filter = "") {
  const { sortBy, sortDesc, page, itemsPerPage } = options;

  return {
    sortBy: firstOrNull(sortBy) || "id",
    sortDesc: stringToBool(firstOrNull(sortDesc)) || false,
    page: page || 1,
    itemsPerPage: itemsPerPage || 10,
    filter: filter || "",
  };
}

function getOptions(params = {}) {
  const { sortBy, sortDesc, page, itemsPerPage } = params;

  return {
    sortBy: [firstOrNull(sortBy) || "id"],
    sortDesc: [stringToBool(firstOrNull(sortDesc)) || false],
    page: page ? Number.parseInt(page) : 1,
    itemsPerPage: itemsPerPage ? Number.parseInt(itemsPerPage) : 10,
  };
}

function firstOrNull(array) {
  if (!Array.isArray(array)) {
    return array;
  }

  if (array.length === 0) {
    return null;
  }

  return array[0];
}

function stringToBool(string) {
  console.log("string", string);
  if (typeof string === "boolean") {
    return string;
  }

  return string === "true" ? true : false;
}

export default {
  getParams,
  getOptions,
};
