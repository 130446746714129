import Vue from "vue";

export async function index(key) {
  const response = await Vue.prototype.$http.get(`/advanced_filters/${key}`);

  return response.data.data;
}

export async function store(key, payload) {
  const response = await Vue.prototype.$http.post(
    `/advanced_filters/${key}`,
    payload
  );

  return response.data.data;
}

export async function rename(id, payload) {
  const response = await Vue.prototype.$http.put(
    `/advanced_filters/${id}/rename`,
    payload
  );

  return response.data.data;
}

export async function destroy(id) {
  const response = await Vue.prototype.$http.delete(`/advanced_filters/${id}`);

  return response;
}
