<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-form @submit.prevent="handleSubmit">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-alert v-if="errorMessage !== ''" type="error" text>
            {{ errorMessage }}
          </v-alert>

          <v-text-field
            v-model="form.name"
            :error-messages="errors.name"
            label="Nome"
            filled
            autofocus
          ></v-text-field>

          <span v-if="errors.advanced_filters" class="red--text">
            {{ errors.advanced_filters[0] }}
          </span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text>Cancelar</v-btn>
          <v-btn color="primary" type="submit"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Filtro Avançado",
    },
  },

  data() {
    return {
      dialog: false,
      form: {},
      errors: {},
      errorMessage: "",
    };
  },

  methods: {
    handleSubmit() {
      this.$emit("submit", this.form);
    },

    open(form = null) {
      this.dialog = true;

      if (form === null) {
        this.reset();
      } else {
        this.form = { ...form };
      }
    },

    setErrors(message = "", errors = {}) {
      this.errors = errors;
      this.errorMessage = message;
    },

    close() {
      this.dialog = false;
    },

    reset() {
      this.form = {
        name: "",
      };
      this.setErrors();
    },
  },
};
</script>
