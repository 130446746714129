<template>
  <v-menu v-bind="$attrs">
    <template #activator="props">
      <slot v-bind="props" name="activator"></slot>
    </template>

    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item[itemKey]"
        @click="handleClickItem(item)"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>

          <v-list-item-subtitle v-if="item.subtitle">
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: Array,
    itemKey: {
      type: String,
      default: "id",
    },
  },

  methods: {
    handleClickItem(item) {
      this.$emit(`click:item:${item[this.itemKey]}`, item);
    },
  },
};
</script>
